<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="!invoice"
      class="alert"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice ID. Check
        <b-link
          class="alert-link"
          :to="{ name: 'invoice-list' }"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <div
      v-if="isLoading"
      class="text-center mb-3"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
      />
    </div>

    <b-row
      v-if="invoice"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="10"
        md="9"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Invoice Header -->
          <b-card-body class="invoice-card-body">
            <div class="invoice-header d-flex justify-content-between align-items-center">
              <div class="company-info">
                <img
                  src="@/assets/images/logo/logo.png"
                  alt="Company Logo"
                  class="logo"
                >
                <div class="company-details">
                  <h3 class="invoice-title">
                    <b style="font-size: large;">Direx </b>
                  </h3>
                  <p class="company-address">
                    <span style="font-size: large;"> Dar chaabene el fehri </span>
                  </p>
                  <p class="company-phone">
                    <span style="font-size: large;">Phone: +216 24 201 201 </span>
                  </p>
                </div>
              </div>
              <div class="invoice-details text-end">
                <h1 class="invoice-number">
                  Facture #{{ invoice.id }}
                </h1>
                <p> <b style="font-size: larger;"> Date de création de facture: {{ formatDate(invoice.created_at) }} </b> </p>
                <p> <b style="font-size: larger;">Période: {{ formatDate(invoice.start_date) }} - {{ formatDate(invoice.end_date) }} </b></p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Customer Details -->
          <b-card-body class="invoice-card-body">
            <div class="customer-details">
              <h6 class="customer-title">
                <b style="font-size: large;color: blue;"> Facture A: </b>
              </h6>
              <p class="customer-name">
                <b style="font-size: large;"> Sonic Delivery </b>
              </p>
              <p class="customer-address">
                <b style="font-size: large;">  <span style="color: blue;">Adresse: </span> Nabeul  </b>
              </p>
              <p class="customer-id">
                <b style="font-size: large;"> <span style="color: blue;"> Matricule Fiscale: </span> 1869354/G/M/A/000  </b>
              </p>
              <p class="customer-phone">
                <b style="font-size: large;"> <span style="color: blue;"> Phone:</span> 26044100/26033100 </b>
              </p>
            </div>
          </b-card-body>

          <!-- Invoice Description Table -->
          <b-card-body class="invoice-card-body">
            <b-table-lite
              responsive
              :items="invoice.order_details"
              :fields="[{ key: 'id', label: 'BL' }, 'price_ttc']"
              class="invoice-table"
            >
              <template #cell(id)="data">
                {{ data.value }}
              </template>
              <template #cell(price_ttc)="data">
                {{ formatCurrency(data.value) }}
              </template>
            </b-table-lite>
          </b-card-body>

          <!-- Invoice Total -->
          <b-card-body class="invoice-card-body">
            <div class="invoice-summary d-flex justify-content-between">
              <div class="status">
                <label>Status:</label>
                <span :class="getStatusClass(invoice.status)">{{ invoice.status_display }}</span>
              </div>
              <div class="totals">
                <p> <b style="font-size: large;"> nombre de colis: {{ invoice.number_of_orders }} </b></p>
                <p> <b style="font-size: large;"> Net A payer: {{ formatCurrency(invoice.total_amount) }}</b></p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Sign -->
          <b-card-body class="invoice-card-body">
            <div class="signature">
              <p><strong>Signature:</strong></p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      invoice: null,
      isLoading: false,
    }
  },
  created() {
    this.getInvoiceDetails()
  },
  methods: {
    async getInvoiceDetails() {
      this.isLoading = true
      try {
        const invoiceId = this.$route.params.id
        const { data } = await axios.get(`/api/invoices/invoice-providers/${invoiceId}`)
        this.invoice = data
      } catch (error) {
        console.error('Error fetching invoice:', error)
        this.$root.$emit('bv::hide::modal') // Hide any open modals if there's an error
      } finally {
        this.isLoading = false
      }
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    },
    formatCurrency(amount) {
      return `${Number(amount).toFixed(2)} TND` // Adjusted to 2 decimal places
    },
    getStatusClass(status) {
      return {
        'status-unpaid': status === 'unpaid',
        'status-paid': status === 'paid',
        'status-default': status !== 'paid' && status !== 'unpaid',
      }
    },
    printInvoice() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-preview-wrapper {
  padding: 30px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  border-radius: 8px;
}

.alert {
  margin-bottom: 20px;
}

.invoice-header {
  margin-bottom: 20px;
}

.logo {
  width: 120px;
  height: auto;
}

.company-details {
  margin-left: 20px;
}

.invoice-title {
  color: #004085;
  font-size: 1.5rem;
}

.company-address, .company-phone {
  margin: 0;
  font-size: 0.9rem;
}

.invoice-details {
  text-align: right;
}

.customer-details {
  border-top: 1px solid #dee2e6;
  padding-top: 15px;
}

.customer-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.invoice-table {
  margin-top: 20px;
  font-size: 0.9rem;
}

.invoice-summary {
  font-size: 0.9rem;
}

.signature {
  text-align: left;
  margin-top: 20px;
}

.status-unpaid {
  color: #dc3545;
}

.status-paid {
  color: #28a745;
}

.status-default {
  color: #6c757d;
}
</style>
